<template>
	<el-form :model="couplingInfo" :ref="refs.couplingsValidation" class="flex flex-col gap-5 p-5">
		<div class="object-couplings__details flex flex-col gap-4">
			<div>
				<span class="objects-couplings__info__label input-label">
					{{ $t('new_units.objects.fields.couplings.trailer') }}
				</span>
				<el-form-item :rules="rules['trailer.id']" prop="trailer.id">
					<el-select :value="couplingInfo.trailer.id" :placeholder="$t('new_units.placeholder.select')" @change="changeTrailer">
						<el-option
							v-for="(item, idx) in allTrailers"
							:key="idx"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>
			</div>
			
			<el-form-item :rules="rules.date_from" prop="date_from">
				<UnitsDateTimePicker
					:date="couplingInfo.date_from"
					:label="$t('new_units.objects.fields.couplings.start_date')"
					:class="{ 'error': errors.includes('date_from') }"
					type="datetime"
					format="dd/MM/yyyy HH:mm"
					@onChange="couplingInfo.date_from = $event"
				/>
			</el-form-item>

			<el-form-item :rules="rules.date_to" prop="date_to">
				<UnitsDateTimePicker
					:date="couplingInfo.date_to"
					:label="$t('new_units.objects.fields.couplings.end_date')"
					:class="{ 'error': errors.includes('date_to') }"
					type="datetime"
					format="dd/MM/yyyy HH:mm"
					@onChange="couplingInfo.date_to = $event"
				/>
			</el-form-item>
			
			<div v-if="errors.length" class="object-couplings__details__note flex items-center gap-3 text-xs font-normal rounded-xl">
				<NoteInfoWarnIcon />
				<span>
					{{ $t('new_units.objects.fields.couplings.note') }}
				</span>
			</div>
			<div>
				<span class="objects-couplings__info__label input-label">
					{{ $t('new_units.objects.fields.couplings.work_type') }}
				</span>
					
				<el-form-item :rules="rules.work_type" prop="work_type">
					<el-select
						v-model="couplingInfo.work_type"
						filterable
						:placeholder="$t('new_units.placeholder.select')"
						:filter-method="filterWork"
					>
						<el-option
							v-for="item in workTypes"
							:key="item"
							:label="item"
							:value="item"
						/>
					</el-select>
					<AddWorkType
						v-if="customWorkType.length" 
						class="object-couplings__details__add-work-type cursor-pointer"
						@click.native="addWorkType"
					/>
				</el-form-item>
			</div>
			<div class="flex flex-col gap-3">
				<div>
					<span class="objects-basic__info__label input-label">{{ $t('new_units.objects.fields.couplings.min_speed') }}</span>
					<el-input v-model="couplingInfo.min_speed" type='number' />
				</div>
				<div>
					<span class="objects-basic__info__label input-label">{{ $t('new_units.objects.fields.couplings.max_speed') }}</span>
					<el-input v-model="couplingInfo.max_speed" />
				</div>
			</div>
			<div>
				<span class="objects-basic__info__label input-label">{{ $t('new_units.objects.fields.couplings.sensor_mask') }}</span>
				<el-input v-model="couplingInfo.sensor_name_mask" />
			</div>
			<ObjectsEditFooter v-if="!coupling.isNew" @onEdit="$emit('onUpdate')" @onCancel="$emit('onCancel', originalCoupling)" />
			<UnitsApproveButton v-else @onApprove="$emit('onApprove')" />
		</div>
	</el-form>
</template>
<script setup>
import { onBeforeMount, ref, watch } from 'vue';

import { createVuexHelpers } from 'vue2-helpers';
import { useI18n } from '@/hooks/useI18n';
import { unitTrailersApi } from '@/api';
import { refs } from '@/components/unitsNew/helpers/index.js';


import UnitsDateTimePicker from '@/components/unitsNew/components/UnitsDateTimePicker.vue';
import NoteInfoWarnIcon from '@/components/unitsNew/assets/icons/note-info_warn.vue';
import AddWorkType from '@/components/unitsNew/assets/icons/add-work_type.vue';
import UnitsApproveButton from '@/components/unitsNew/components/UnitsApproveButton.vue';
import ObjectsEditFooter from '@/components/unitsNew/components/Objects/ObjectsEditFooter.vue';

const { useGetters } = createVuexHelpers();

const $t = useI18n()

const { allTrailers } = useGetters('trailers', ['allTrailers']);

const props = defineProps({
	coupling: {
		type: Object,
		default: () => ({})
	},
	errors: {
		type: Array,
		default: () => []
	}
})

const rules = ref({
	'trailer.id': [{ required: true, message: $t('new_units.objects.fields.couplings.errors.trailer') }],
	date_from: [{ required: true, message: $t('new_units.objects.fields.couplings.errors.couplig_start') }],
	date_to: [{ required: true, message: $t('new_units.objects.fields.couplings.errors.couplig_end') }],
	work_type: [{ required: true, message: $t('new_units.objects.fields.couplings.errors.work_type') }],
})

const couplingInfo = ref({})
const originalCoupling = ref({})

const workTypes = ref([])
const customWorkType = ref('')

const getWorkTypes = () => {
	unitTrailersApi.getWorkTypes(
		(response) => {
			workTypes.value = response.data
		},
		(error) => {
			throw new Error(error)
		}
	)
}

const changeTrailer = (trailer) => {
	props.coupling.trailer = allTrailers.value.find(item => item.id === trailer)
}

const filterWork = (value) => {
	customWorkType.value = value
}

const addWorkType = () => {
	workTypes.value.push(customWorkType.value)

	couplingInfo.value.work_type = customWorkType.value
	customWorkType.value = ''
}

watch(() => props.coupling, (coupling) => {
	if (Object.keys(props.coupling).length) {
		couplingInfo.value = coupling
		originalCoupling.value = JSON.parse(JSON.stringify(coupling))
	}
}, { immediate: true })

onBeforeMount(() => {
	getWorkTypes()
})
</script>
